import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import AutoScrollable from "../global/AutoScrollable";
import { LoginContext } from "./Login";
import Post from "./Post";
import ReplyForm from "./ReplyForm";
import { Link } from "react-router-dom";
import {
  addHeaderTagOnce,
  cutLexicalString,
  getCookie,
  isBot,
  removeHeaderTag,
  setCookie,
  transliterateLink,
} from "../global/Global";
import Checkbox from "./Checkbox";
import useDidUpdateEffect from "../global/DidUpdateEffect";
import NotFound from "./NotFound";
import CheckboxSwitcher from "./CheckboxSwitcher";
import TabsSwitcher from "./TabsSwitcher";
import Tooltip from "./Tooltip";

const Posts = () => {
  const location = useLocation();
  const { toComments } = location.state || {};
  const [posts, setPosts] = useState([]);
  const [postsMap, setPostsMap] = useState({});
  const [postsSimilar, setPostsSimilar] = useState([]);
  const [postsAllLoaded, setPostsAllLoaded] = useState(false);
  const [limitedResponce, setLimitedResponce] = useState(false);
  let [forum, setForum] = useState();
  const [notFound, setNotFound] = useState(false);
  const [directRead, setDirectRead] = useState(
    getCookie("readorder") == "direct" || !getCookie("readorder"),
  );
  const [stickFirst, setStickFirst] = useState(!!getCookie("displayfirst"));
  let { threadid } = useParams();
  if (threadid) {
    threadid = threadid.split("-")[0];
  }
  const previousThreadid = useRef(threadid);
  let { postid } = useParams();
  let needScrollToPost = useRef(postid);
  const { user } = useContext(LoginContext);
  const [replyAfterPostid, setReplyAfterPostid] = useState(0);
  const postsListsRef = useRef();
  const [replyPostEnabled, setReplyPostEnabled] = useState(true);
  const toCommentsRef = useRef(toComments || false);

  if (!threadid && previousThreadid?.current) {
    threadid = previousThreadid.current;
  }

  useDidUpdateEffect(() => {
    if (!threadid || threadid == 0 || threadid == previousThreadid.current)
      return;

    logT("posts", "changed threadid", threadid);
    previousThreadid.current = threadid;
    window.loadedAllPosts = false;
    setPostsSimilar([]);
    fetchData(true, true);
  }, [threadid]);

  useDidUpdateEffect(() => {
    if (!postid) return;

    logT("posts", "changed postid", postid, threadid);
    window.loadedAllPosts = false;
    threadid = 0;
    needScrollToPost.current = postid;
    setPostsSimilar([]);
    fetchData(true, true);
  }, [postid]);

  useDidUpdateEffect(() => {
    if (!posts || posts.length == 0) return;

    let scrollEl;

    if (needScrollToPost?.current) {
      logT("scroll", "scroll to post index", needScrollToPost.current);
      scrollEl = document.getElementById(`post_${needScrollToPost.current}`);
      needScrollToPost.current = null;
    }

    if (toCommentsRef?.current) {
      logT("scroll", "scroll to comments section");
      let scrollPost = posts[0];
      if (scrollPost.parentid == 0 && posts.length > 1) {
        scrollPost = posts[1];
      }
      scrollEl = document.getElementById(`post_${scrollPost.postid}`);
      toCommentsRef.current = false;
    }

    if (scrollEl) {
      scrollEl.scrollIntoView({ block: "start" });
      const OFFSET = 64 - 118; // header size - padding scroll offest
      window.scrollTo(0, Math.max(window.pageYOffset - OFFSET, 0)); // header size
    }
  }, [posts]);

  const rebuldPostMap = () => {
    Object.keys(postsMap).forEach((key) => {
      const post = postsMap[key];
      if (post.parentid && postsMap[post.parentid]) {
        post.parent = postsMap[post.parentid];
        if (!post.parent.childs) post.parent.childs = {};
        post.parent.childs[post.postid] = post;
      }
    });
    setPostsMap(Object.assign({}, postsMap));
  };

  useEffect(() => {
    window.loadingFinish("posts");
  }, [posts]);

  const fetchData = async (full = false, updateSimilar = false) => {
    if (!full && window.loadedAllPosts) {
      logT("posts", "loaded all posts, ignore request");
      return;
    }

    let index = (posts && posts.length) || 0;
    let limit = 10;
    if (full) {
      index = 0;
      limit = Math.max(10, posts.length + 1); // can be new post thats why + 1
    }
    const token = getCookie("token") || "";
    const userid = parseInt(getCookie("userid")) || 0;
    // manual increase value to make sure all tags set
    window.loadingStart();
    const data = await window.TALKVIOAPI("posts", {
      source: "posts",
      threadid: parseInt(threadid) || 0,
      untilPostid: (!threadid && parseInt(postid)) || 0,
      index,
      recent: getCookie("readorder") == "reverse",
      popular: getCookie("readorder") == "popular",
      limit,
      userid,
      token,
      firstPostAlwaysTop: !!getCookie("displayfirst"),
    });
    // already loaded everything
    if (!full && data.posts.length == 0) {
      window.loadedAllPosts = true;
    } else {
      window.loadedAllPosts = false;
    }
    if (!threadid && postid && postid > 0 && data.posts?.length > 0) {
      threadid = data.posts[0].threadid;
      previousThreadid.current = threadid;
      postid = 0;
      logT("posts", "get post threadid", threadid);
    }
    let firstTime = posts && posts.length == 0;

    if (data.limitedResponce) {
      logT("posts", "limited responce, show full load button");
      setLimitedResponce(true);
    } else if (full) {
      setLimitedResponce(false);
    }

    if (data.posts?.length > 0 || full) {
      // build postmap
      Object.keys(data.postsMap).forEach((key) => {
        // prefer posts over postMap and keep postMap always filled with posts
        if (full || !postsMap[key]) postsMap[key] = data.postsMap[key];
      });
      data.posts.forEach((post) => (postsMap[post.postid] = post));
      rebuldPostMap();

      window.loadingFinish("posts"); // in case previusly loaded
      window.loadingStart("posts");

      if (!full) setPosts((posts || []).concat(data.posts));
      else setPosts(data.posts);
    } else {
      logT("posts", "nothing to load, 0 new posts, ignore posts update");
    }

    if (data.posts.length > 0 && data.posts[0].forumid != forum?.forumid) {
      if (data.posts[0].forumid) {
        logT("forum", "assign forumid", data.posts[0].forumid);
        setForum({
          forumid: data.posts[0].forumid,
          title: data.posts[0].forumtitle,
        });
      } else if (forum?.forumid) {
        logT("forum", "clear forumid");
        setForum(null);
      }
    }

    let isNotFound = false;
    if (firstTime) {
      if (!data.posts || data.posts.length == 0) {
        setNotFound(true);
        isNotFound = true;
      } else {
        setNotFound(false);
        isNotFound = false;
      }
    }

    if (data.posts.length == 0 && !isNotFound) {
      logT("posts", "all posts loaded, show similar posts");
      setPostsAllLoaded(true);
    } else if (full) {
      setPostsAllLoaded(false);
    }

    if (
      data.posts.length > 0 &&
      data.posts[0].threadtitle &&
      data.posts[0].threadtitle.length > 0
    ) {
      document.title = `${data.posts[0].threadtitle} - Talkvio`;
      const titleUrl = transliterateLink(data.posts[0].threadtitle);
      let caninicalUrl = `https://talkvio.com/threads/${threadid}`;
      if (titleUrl?.length > 0) {
        window.history.replaceState(
          null,
          document.title,
          `/threads/${threadid}-${titleUrl}`,
        );
        caninicalUrl = `https://talkvio.com/threads/${threadid}-${titleUrl}`;
      }
      // Something wrong (googlebot index failure)
      if (threadid != data.posts[0].threadid) {
        window.talkvioFailure = true;
        logTE(
          "posts",
          "threadid incorrect router:",
          threadid,
          "fetch:",
          data.posts[0].threadid,
        );
      }

      // <link rel="canonical" href="https://www.example.com/canonical-url">
      addHeaderTagOnce("canonical", caninicalUrl, "rel", "href", "link");

      // og tags
      addHeaderTagOnce("og:title", data.posts[0].threadtitle, "property");
      addHeaderTagOnce("og:url", "https://talkvio.com", "property");
      addHeaderTagOnce("og:type", "article", "property");

      // twitter tags
      addHeaderTagOnce("twitter:card", "summary_large_image", "name");
      addHeaderTagOnce("twitter:site", "@talkvio", "name");
      addHeaderTagOnce("twitter:title", data.posts[0].threadtitle, "name");
      if (titleUrl?.length > 0) {
        addHeaderTagOnce(
          "twitter:url",
          `https://talkvio.com/threads/${threadid}-${titleUrl}`,
          "name",
        );
      }
      addHeaderTagOnce("twitter:domain", "talkvio.com", "name");
    }

    if (
      data.posts.length > 0 &&
      data.posts[0].taglist &&
      data.posts[0].taglist.length > 0
    ) {
      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", data.posts[0].taglist);
    }

    if (data.posts.length > 0 && data.posts[0].threadtitle) {
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          cutLexicalString(`${data.posts[0].threadtitle}`, 160),
        );
    }

    // manual decrease value to make sure all tags set
    window.loadingFinish();

    if (firstTime && data.posts.length > 0)
      window.TALKVIOAPI("view", {
        threadid: parseInt(threadid) || 0,
        postid: parseInt(data.posts[0].firstpostid) || 0,
        userid,
        token,
      });

    if (
      (postsSimilar?.length === 0 || updateSimilar) &&
      data.posts.length > 0
    ) {
      logT("simiar", "update simiar posts");
      const dataSimilar = await window.TALKVIOAPI(
        "posts",
        {
          similar: true,
          threadid: parseInt(threadid) || 0,
        },
        { mark: "similar" },
      );
      if (dataSimilar.posts?.length > 0) {
        logT("similar", "similar posts", dataSimilar.posts);
        setPostsSimilar(dataSimilar.posts);
      }
    }
  };

  window.TALKVIO_ON(
    "addPost",
    (post) => {
      logT("socket", "addPost", post);
      if (post.threadid == parseInt(threadid)) {
        fetchData(true);
      }
    },
    "posts",
  );

  window.TALKVIO_ON(
    "prepublishPost",
    (post) => {
      logT("socket", "prepublishPost", post);
      if (post.threadid == parseInt(threadid)) {
        fetchData(true);
      }
    },
    "posts",
  );

  window.TALKVIO_ON(
    "updateTags",
    (post) => {
      logT("socket", "update tags", post, "tags", post.tags);
      if (post.threadid == parseInt(threadid)) {
        fetchData(true);
      }
    },
    "posts",
  );

  window.TALKVIO_ON(
    "updatePostForum",
    (post) => {
      logT("socket", "update forum", post, "forum", post.forumid);
      if (post.threadid == parseInt(threadid)) {
        fetchData(true);
      }
    },
    "posts",
  );

  window.TALKVIO_ON(
    "deletePost",
    (post) => {
      logT("socket", "deletePost", post);
      if (post.threadid == parseInt(threadid)) {
        fetchData(true);
      }
    },
    "posts",
  );

  window.TALKVIO_ON(
    "editPost",
    (post) => {
      logT("socket", "editPost", post);
      if (post.threadid == parseInt(threadid)) {
        forum = null; // force forum reset
        fetchData(true);
      }
    },
    "posts",
  );

  window.TALKVIO_ON(
    "changePostRating",
    (post) => {
      logT("socket", "changePostRating", post);
      if (post.threadid == parseInt(threadid)) {
        const p = postsMap[post.postid];
        if (p) {
          // don't refetch because we don't want change order of post on rating change
          p.postrating = post.postrating;
          p.rating_all = post.rating_all;
          p.rating_bad = post.rating_bad;
          p.rating_good = post.rating_good;
          if (user?.userid == post.userid) {
            if (post.changeRating) {
              p.setrating = null;
            } else {
              if (post.ratingReduce) {
                p.setrating = "-";
              } else {
                p.setrating = "+";
              }
            }
            logT(
              "rating",
              "current user change rating",
              "change =",
              post.changeRating,
              "reduce =",
              post.ratingReduce,
            );
          }
          setPosts(posts.slice());
        }
      }
    },
    "posts",
  );

  window.TALKVIO_ON(
    "subscription",
    (sub) => {
      logT("socket", "subscription", sub);
      for (const p of posts) {
        if (p.userid == sub.targetuserid) {
          fetchData(true);
          break;
        }
      }
    },
    "posts",
  );

  window.TALKVIO_ON(
    "subscriptionThread",
    (sub) => {
      if (sub.userid != user?.userid) {
        return;
      }
      logT("socket", "subscriptionThread", sub);
      if (posts.length > 0 && posts[0].threadid == sub.threadid) {
        fetchData(true);
      }
    },
    "posts",
  );

  window.TALKVIO_ON(
    "bookmark",
    (bookmark) => {
      if (bookmark.userid != user?.userid) {
        return;
      }
      logT("socket", "bookmark", bookmark);
      for (const p of posts) {
        if (p.postid == bookmark.postid) {
          fetchData(true);
          break;
        }
      }
    },
    "posts",
  );

  window.TALKVIO_ON(
    "raisePost",
    (post) => {
      if (post.threadid == parseInt(threadid)) {
        logT("socket", "raise post", post.postid, "for thread");
        fetchData(true);
      }
    },
    "posts",
  );

  window.TALKVIO_ON(
    "fileFinished",
    (attachment) => {
      for (const p of posts) {
        if (p.postid == attachment.contentid) {
          logT("socket", "file processed finished", p.postid);
          fetchData(true);
          break;
        }
      }
    },
    "posts",
  );

  useEffect(() => {
    window.loadedAllPosts = false;
    fetchData();

    const ALIGN_TOP = 64;
    const previosPost = () => {
      const scroll = document.documentElement.scrollTop;
      if (!postsListsRef?.current) return;

      const childElements = postsListsRef.current?.children;
      if (childElements?.length > 0) {
        for (let i = childElements.length - 1; i >= 0; i--) {
          const child = childElements[i];
          const elementPosition = child.getBoundingClientRect();
          if (elementPosition.top < -100) {
            logT(
              "key",
              "scroll to previus post",
              i,
              elementPosition.top,
              scroll,
            );
            window.scrollTo({
              top: scroll + elementPosition.top - ALIGN_TOP,
              behavior: "smooth",
            });
            return;
          }
        }
      }
    };
    const nextPost = () => {
      const scroll = document.documentElement.scrollTop;
      if (!postsListsRef?.current) return;

      const childElements = postsListsRef.current?.children;
      if (childElements?.length > 0) {
        for (let i = 0; i < childElements.length; i++) {
          const child = childElements[i];
          const elementPosition = child.getBoundingClientRect();
          if (elementPosition.top > 100) {
            logT("key", "scroll to next post", i, elementPosition.top, scroll);
            window.scrollTo({
              top:
                scroll +
                elementPosition.top -
                ALIGN_TOP +
                (!window.talkvioHeaderFixed ? ALIGN_TOP : 0),
              behavior: "smooth",
            });
            return;
          }
        }
      }
    };
    const findCurrentScrollPost = () => {
      const scroll = document.documentElement.scrollTop;
      if (!postsListsRef?.current) return;

      const childElements = postsListsRef.current?.children;
      if (childElements?.length > 0) {
        for (let i = 0; i < childElements.length; i++) {
          const child = childElements[i];
          const elementPosition = child.getBoundingClientRect();
          if (
            Math.abs(
              elementPosition.top -
                ALIGN_TOP +
                (window.talkvioHeaderOffset || 0),
            ) < 40
          ) {
            const postid = child.getAttribute("data-postid");
            logT(
              "key",
              "post find",
              postid,
              "align",
              elementPosition.top -
                ALIGN_TOP +
                (window.talkvioHeaderOffset || 0),
            );
            return postid;
          }
        }
      }
    };
    let blocking = false;
    const upPost = async () => {
      if (blocking) return;

      const postid = parseInt(findCurrentScrollPost());
      if (postid) {
        logT("rating", "change up postid", postid);
        const token = getCookie("token");
        const userid = parseInt(getCookie("userid"));
        if (userid && token) {
          blocking = true;
          const data = await window.TALKVIOAPI("changePostRating", {
            userid,
            token,
            postid: postid,
            reduce: false,
          });
          blocking = false;
          window.displayError(data);
        } else {
          if (window.needRegistationAction) window.needRegistationAction();
        }
      }
    };
    const downPost = async () => {
      if (blocking) return;

      const postid = parseInt(findCurrentScrollPost());
      if (postid) {
        logT("rating", "change down postid", postid);
        const token = getCookie("token");
        const userid = parseInt(getCookie("userid"));
        if (userid && token) {
          blocking = true;
          const data = await window.TALKVIOAPI("changePostRating", {
            userid,
            token,
            postid: postid,
            reduce: true,
          });
          blocking = false;
          window.displayError(data);
        } else {
          if (window.needRegistationAction) window.needRegistationAction();
        }
      }
    };

    window.KEY_ON("d", nextPost, "posts");
    window.KEY_ON("в", nextPost, "posts");
    window.KEY_ON("a", previosPost, "posts");
    window.KEY_ON("ф", previosPost, "posts");
    window.KEY_ON("w", upPost, "posts");
    window.KEY_ON("ц", upPost, "posts");
    window.KEY_ON("s", downPost, "posts");
    window.KEY_ON("ы", downPost, "posts");

    window.setLeftPanelTopOffet(115);
    window.setLeftPanelDisplay(true);

    window.setRightPanelTopOffet(115);
    window.setRightPanelDisplay(true);

    return () => {
      window.resetLeftPanelTopOffet();
      window.setLeftPanelDisplay(false);

      window.resetRightPanelTopOffet();
      window.setRightPanelDisplay(false);

      window.KEY_OFF("d", nextPost);
      window.KEY_OFF("в", nextPost);
      window.KEY_OFF("a", previosPost);
      window.KEY_OFF("ф", previosPost);
      window.KEY_OFF("w", upPost);
      window.KEY_OFF("ц", upPost);
      window.KEY_OFF("s", downPost);
      window.KEY_OFF("ы", downPost);

      logT("post", "clean all headers and metags");
      removeHeaderTag("og:title", "property");
      removeHeaderTag("og:url", "property");
      removeHeaderTag("og:type", "property");
      removeHeaderTag("og:image", "property");
      removeHeaderTag("og:description", "property");

      removeHeaderTag("twitter:card", "name");
      removeHeaderTag("twitter:site", "name");
      removeHeaderTag("twitter:title", "name");
      removeHeaderTag("twitter:url", "name");
      removeHeaderTag("twitter:domain", "name");
      removeHeaderTag("twitter:image", "name");
      removeHeaderTag("twitter:description", "name");
      removeHeaderTag("twitter:player", "name");
      removeHeaderTag("twitter:player:width", "name");
      removeHeaderTag("twitter:player:height", "name");

      removeHeaderTag("canonical", "rel", "link");
    };
  }, []);

  if (notFound) {
    return <NotFound />;
  }

  return (
    <div className="postsDisplay">
      <div className="threadTitle">
        <div className="titleBlock">
          {posts?.[0]?.parentid != 0 && posts[0]?.threadtitle ? (
            <>
              <Link
                to={
                  "/threads/" +
                  posts[0].threadid +
                  `-${transliterateLink(posts[0].threadtitle)}`
                }
              >
                {posts[0].original || posts[0].threadoriginal ? (
                  <span style={{ fontWeight: "bold" }}>[МОЁ] </span>
                ) : (
                  ""
                )}
                {posts[0].threadtitle}
              </Link>
              {forum && (
                <Link to={"/forums/" + forum.forumid} className="forumTitle">
                  {"<<"} {forum.title}
                </Link>
              )}
            </>
          ) : (
            <span></span>
          )}
        </div>
        {!directRead ? (
          <CheckboxSwitcher
            text={__("Stick first post")}
            className="stickPost"
            value={stickFirst}
            onSwitch={(checked) => {
              setCookie("displayfirst", checked, 30);
              setStickFirst(checked);
              fetchData(true);
            }}
          />
        ) : null}
        <div className="direction">
          <Tooltip hint={__("Direction of comments or posts reading")}>
            <TabsSwitcher
              slow={true}
              value={getCookie("readorder") || "direct"}
              onChange={(value) => {
                if (value == "direct") {
                  setCookie("readorder", "direct", 30);
                  setDirectRead(true);
                  fetchData(true);
                } else if (value == "popular") {
                  setCookie("readorder", "popular", 30);
                  setDirectRead(false);
                  fetchData(true);
                } else {
                  setCookie("readorder", "reverse", 30);
                  setDirectRead(false);
                  fetchData(true);
                }
              }}
              values={{
                direct: "Сначала старые",
                reverse: "Сначала новые",
                popular: "Популярные",
              }}
            />
          </Tooltip>
        </div>
      </div>
      {!directRead && replyAfterPostid == 0 && !stickFirst && (
        <ReplyForm
          className="typicalBlock"
          user={user}
          threadid={threadid}
          minimal={true}
          onReply={(data) => {
            needScrollToPost.current = data?.post?.postid;
          }}
        />
      )}
      <AutoScrollable
        className="w100p flex row center-row"
        onBottomScroll={() => {
          if (limitedResponce) {
            logT("posts", "ignore scroll as limited posts");
            return;
          }
          fetchData();
        }}
      >
        <ul className="posts postslist" ref={postsListsRef}>
          {posts.map((post, index) => (
            <>
              <Post
                source="posts"
                post={post}
                key={post.postid}
                user={user}
                forum={forum}
                enableDataPostid={true}
                showReply={true}
                fetchPosts={fetchData}
                onReplyClick={(postid) => {
                  setReplyAfterPostid(postid);
                  setReplyPostEnabled(true);
                }}
                noFirstImageAdult={true}
                onFirstImage={
                  post.parentid == 0
                    ? (image) => {
                        // On first image
                        addHeaderTagOnce("og:image", image, "property");
                        addHeaderTagOnce("twitter:image", image, "name");
                      }
                    : null
                }
                onFirstVideoPreviewImage={
                  post.parentid == 0
                    ? (previewImage) => {
                        // On first image
                        addHeaderTagOnce("og:image", previewImage, "property");
                        addHeaderTagOnce("twitter:image", previewImage, "name");
                      }
                    : null
                }
                onFirstTextBlock={
                  post.parentid == 0
                    ? (text) => {
                        if (!text || text.length == 0) return;

                        addHeaderTagOnce(
                          "og:description",
                          cutLexicalString(text, 55, "..."),
                          "property",
                        );
                        addHeaderTagOnce(
                          "twitter:description",
                          cutLexicalString(text, 200, "..."),
                          "name",
                        );

                        const descriptionText = cutLexicalString(
                          text?.length > 0 ? text : post.threadtitle,
                          200,
                          "...",
                        );
                        logT(
                          "posts",
                          "parse initial base post for description content =",
                          descriptionText,
                        );
                        document
                          .querySelector('meta[name="description"]')
                          .setAttribute("content", descriptionText);
                      }
                    : null
                }
                onFirstYoutubeBlock={
                  post.parentid == 0
                    ? (youtubeLink) => {
                        addHeaderTagOnce("twitter:card", "player", "name");
                        addHeaderTagOnce(
                          "twitter:player",
                          "https://www.youtube.com/embed/" + youtubeLink,
                          "name",
                        );
                        addHeaderTagOnce(
                          "twitter:player:width",
                          "1280",
                          "name",
                        );
                        addHeaderTagOnce(
                          "twitter:player:height",
                          "720",
                          "name",
                        );
                        addHeaderTagOnce(
                          "twitter:image",
                          `https://i.ytimg.com/vi/${youtubeLink}/hqdefault.jpg`,
                          "name",
                        );
                      }
                    : null
                }
              />
              {user?.userid > 0 &&
              (replyAfterPostid == post.postid ||
                (index == 0 &&
                  (stickFirst || directRead) &&
                  !replyAfterPostid)) ? (
                <ReplyForm
                  key={"reply-post-" + post.postid}
                  className="typicalBlock"
                  replyPost={post}
                  user={user}
                  threadid={threadid}
                  replyPostEnabled={replyPostEnabled}
                  setReplyPostEnabled={setReplyPostEnabled}
                  onReply={(data) => {
                    setReplyAfterPostid(0);
                    needScrollToPost.current = data?.post?.postid;
                  }}
                  minimal={
                    index == 0 &&
                    (stickFirst || directRead) &&
                    !replyAfterPostid
                  }
                />
              ) : null}
              {limitedResponce && index == 0 ? (
                <div
                  to={"/threads/" + post.threadid}
                  className="clickButton margin-center text-wrap fit-content"
                  onClick={() => {
                    setPosts([]);
                    setPostsMap({});
                    setLimitedResponce(false);
                    setTimeout(() => fetchData(), 1);
                  }}
                >
                  {__(
                    "Comment responce limited as big thread, back to full thread",
                  )}
                </div>
              ) : null}
            </>
          ))}
          {!window.talkvioBot &&
          !isBot() &&
          postsSimilar.length > 0 &&
          postsAllLoaded ? (
            <div className="similarPosts">
              <div className="similarPostsTitle">{__("Similar posts")}</div>
              {postsSimilar.map((post) => (
                <Post
                  key={post.postid}
                  post={post}
                  preview={true}
                  collapsable={true}
                />
              ))}
            </div>
          ) : null}
        </ul>
      </AutoScrollable>
      {directRead && replyAfterPostid == 0 && posts.length > 5 && (
        <ReplyForm
          className="typicalBlock"
          user={user}
          threadid={threadid}
          onReply={(data) => {
            needScrollToPost.current = data?.post?.postid;
          }}
          minimal={true}
        />
      )}
    </div>
  );
};
export default Posts;
