import React, { createContext, useEffect, useState } from "react";
import { getCookie, setCookie } from "../global/Global";
import moment from "moment/moment";
import "moment/locale/ru";

export const TranslationContext = createContext();

const Translations = ({ children }) => {
  if (!window.forceLanguage && getCookie("language")) {
    window.forceLanguage = getCookie("language");
  }
  const [language, setLanguage] = useState(getPreferredLanguage());
  moment.locale(language);
  const htmlElement = document.getElementsByTagName("html");
  if (htmlElement?.[0]) {
    htmlElement[0].setAttribute("lang", language);
  }
  const setTranslation = (lang = "en") => {
    if (lang == "auto") {
      setCookie("language", "");
      delete window.forceLanguage;
    } else {
      window.forceLanguage = lang;
      setCookie("language", lang);
    }
    setLanguage(lang);
  };

  useEffect(() => {
    window.TALKVIOAPI("talkvio", {
      client: "web",
      language: language,
    });
  }, [language]);

  return (
    <TranslationContext.Provider
      value={{
        setTranslation,
      }}
    >
      <div className="App" key={language}>
        {children}
      </div>
    </TranslationContext.Provider>
  );
};
export default Translations;
